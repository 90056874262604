import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LanguageConfig from "../Common/LanguageConfig";
import { Translate } from "react-translated";
import { FaFileDownload } from "react-icons/fa";

import slideshow1 from "./img/slideshow_seljuks/1.png";
import slideshow2 from "./img/slideshow_seljuks/2.png";
import slideshow3 from "./img/slideshow_seljuks/3.png";
import slideshow4 from "./img/slideshow_seljuks/4.png";
import slideshow5 from "./img/slideshow_seljuks/5.png";
import slideshow6 from "./img/slideshow_seljuks/6.png";
import slideshow7 from "./img/slideshow_seljuks/7.png";
import slideshow8 from "./img/slideshow_seljuks/8.png";
import slideshow9 from "./img/slideshow_seljuks/9.png";

import slideshow1_thumb from "./img/slideshow_seljuks_100/1.png";
import slideshow2_thumb from "./img/slideshow_seljuks_100/2.png";
import slideshow3_thumb from "./img/slideshow_seljuks_100/3.png";
import slideshow4_thumb from "./img/slideshow_seljuks_100/4.png";
import slideshow5_thumb from "./img/slideshow_seljuks_100/5.png";
import slideshow6_thumb from "./img/slideshow_seljuks_100/6.png";
import slideshow7_thumb from "./img/slideshow_seljuks_100/7.png";
import slideshow8_thumb from "./img/slideshow_seljuks_100/8.png";
import slideshow9_thumb from "./img/slideshow_seljuks_100/9.png";

import slideshow1_300 from "./img/slideshow_seljuks_300/2.png";
import slideshow2_300 from "./img/slideshow_seljuks_300/3.png";
import slideshow3_300 from "./img/slideshow_seljuks_300/4.png";
import slideshow4_300 from "./img/slideshow_seljuks_300/9.png";

const slideshow = [
  slideshow1,
  slideshow2,
  slideshow3,
  slideshow4,
  slideshow5,
  slideshow6,
  slideshow7,
  slideshow8,
  slideshow9,
];
const slideshow_thumbnail = [
  slideshow1_thumb,
  slideshow2_thumb,
  slideshow3_thumb,
  slideshow4_thumb,
  slideshow5_thumb,
  slideshow6_thumb,
  slideshow7_thumb,
  slideshow8_thumb,
  slideshow9_thumb,
];

export default class HomepageSeljukBeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expended: false,
    };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand() {
    this.setState({
      expended: !this.state.expended,
    });
  }

  render() {
    const { expended } = this.state;

    return (
      <section class="page-section clearfix">
        <div class="maincontent">
          <div className="bg-faded p-5 rounded">
            <button
              style={{ float: "right", marginRight: "10px", marginTop: "10px" }}
              type="button"
              className="btn btn-primary"
              onClick={this.toggleExpand}
            >
              <Translate text={expended ? "shrink" : "expand"} />
            </button>

            <h2 class="section-heading mb-4">
              <span class="section-heading-lower">
                <Translate text="seljukbeta_title" />
              </span>
            </h2>

            {expended && (
              <div style={{ minHeight: "450px" }}>
                <Release8SlideshowLarge className="ml-2 mb-2 slideshowbeta" />
                {LanguageConfig.getHTML("seljukbeta_details")}
                <div
                  style={{ textAlign: "center" }}
                >
                  <a
                    href={
                      "/php/dlpage.php?file=Millenaire8.1-beta.1.zip"
                    }
                  >
                    <FaFileDownload className="iconDL" />
                    <br />
                    <Translate text="ind_mod_download" /> (
                    <Translate text="ind_mod_client_server" />)
                  </a>
                </div>
              </div>
            )}
            {!expended && (
              <div style={{ minHeight: "180px" }}>
                {LanguageConfig.getHTML("seljukbeta_desc")}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <img
                    src={slideshow1_300}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    alt=""
                  />
                  <img
                    src={slideshow2_300}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    alt=""
                  />
                  <img
                    src={slideshow3_300}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    alt=""
                  />
                  <img
                    src={slideshow4_300}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

class Release8SlideshowLarge extends React.Component {
  render() {
    const { className, style } = this.props;

    const images = [];

    for (let i = 0; i < slideshow.length; i++) {
      images[i] = {
        original: slideshow[i],
        thumbnail: slideshow_thumbnail[i],
        description: LanguageConfig.getText("slideshow_seljukbeta_" + (i + 1)),
      };
    }

    return (
      <div className={className} style={style}>
        <div className="slideshow">
          <div className="slideshow-text left-0">
            <ImageGallery items={images} showThumbnails={true} />
          </div>
        </div>
      </div>
    );
  }
}
